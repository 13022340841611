import { default as _91id_93lF9NaTJ04qMeta } from "/tmp/8dd1e17a081b358/pages/applicant/[id].vue?macro=true";
import { default as _91id_93SQHILYDm2dMeta } from "/tmp/8dd1e17a081b358/pages/apply/[id].vue?macro=true";
import { default as _91id_93YuNYfxN2PmMeta } from "/tmp/8dd1e17a081b358/pages/apply/perfil/[id].vue?macro=true";
import { default as thankstI2kvdAtnbMeta } from "/tmp/8dd1e17a081b358/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93sVB37WtKQBMeta } from "/tmp/8dd1e17a081b358/pages/auth/password/[...slug].vue?macro=true";
import { default as forgot4HIDXvsKfbMeta } from "/tmp/8dd1e17a081b358/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93PXgWDAIAkEMeta } from "/tmp/8dd1e17a081b358/pages/auth/signin/[...slug].vue?macro=true";
import { default as index8ykIqAjmPqMeta } from "/tmp/8dd1e17a081b358/pages/auth/signin/index.vue?macro=true";
import { default as _91email_93ykYwuWbp8wMeta } from "/tmp/8dd1e17a081b358/pages/auth/signup/[email].vue?macro=true";
import { default as indexXZY6WM3xQuMeta } from "/tmp/8dd1e17a081b358/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_93PGhsnTnL8uMeta } from "/tmp/8dd1e17a081b358/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as jobs1bVweZCT6SMeta } from "/tmp/8dd1e17a081b358/pages/company/[id]/jobs.vue?macro=true";
import { default as indexu1ZiPEqH54Meta } from "/tmp/8dd1e17a081b358/pages/company/index.vue?macro=true";
import { default as onboardingCe1EEV7eYBMeta } from "/tmp/8dd1e17a081b358/pages/company/onboarding.vue?macro=true";
import { default as indexiwQOstk2deMeta } from "/tmp/8dd1e17a081b358/pages/index.vue?macro=true";
import { default as _91applicant_93wPFmjrIPcdMeta } from "/tmp/8dd1e17a081b358/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as indexqd6DYvdlgkMeta } from "/tmp/8dd1e17a081b358/pages/job/[job]/applicants/index.vue?macro=true";
import { default as duplicate9RxCxmzadyMeta } from "/tmp/8dd1e17a081b358/pages/job/[job]/duplicate.vue?macro=true";
import { default as index3JcNPGAOxfMeta } from "/tmp/8dd1e17a081b358/pages/job/[job]/index.vue?macro=true";
import { default as archivedpc6yBFGAy0Meta } from "/tmp/8dd1e17a081b358/pages/job/archived.vue?macro=true";
import { default as closeda5rCXpW5UUMeta } from "/tmp/8dd1e17a081b358/pages/job/closed.vue?macro=true";
import { default as newSdsDPD1K4SMeta } from "/tmp/8dd1e17a081b358/pages/job/new.vue?macro=true";
import { default as openedvWfhltiTIiMeta } from "/tmp/8dd1e17a081b358/pages/job/opened.vue?macro=true";
import { default as pausedb9orQ8fuHlMeta } from "/tmp/8dd1e17a081b358/pages/job/paused.vue?macro=true";
import { default as indexhgJJlwMGWlMeta } from "/tmp/8dd1e17a081b358/pages/plans/index.vue?macro=true";
import { default as settingska5LQnMuLjMeta } from "/tmp/8dd1e17a081b358/pages/settings.vue?macro=true";
import { default as indexTVLtO2QwbbMeta } from "/tmp/8dd1e17a081b358/pages/team/index.vue?macro=true";
import { default as indexCucEYoo0P5Meta } from "/tmp/8dd1e17a081b358/pages/thanks/index.vue?macro=true";
import { default as indexQ8qwcDyOrgMeta } from "/tmp/8dd1e17a081b358/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93lF9NaTJ04qMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/applicant/[id].vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd1e17a081b358/pages/apply/[id].vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd1e17a081b358/pages/apply/perfil/[id].vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd1e17a081b358/pages/apply/thanks.vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93PXgWDAIAkEMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: index8ykIqAjmPqMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/signup/[email].vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexXZY6WM3xQuMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/signup/index.vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd1e17a081b358/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd1e17a081b358/pages/company/[id]/jobs.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexu1ZiPEqH54Meta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/company/index.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingCe1EEV7eYBMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/company/onboarding.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexiwQOstk2deMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93wPFmjrIPcdMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/[job]/applicants/[applicant].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: indexqd6DYvdlgkMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicate9RxCxmzadyMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/[job]/duplicate.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: index3JcNPGAOxfMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/[job]/index.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedpc6yBFGAy0Meta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/archived.vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closeda5rCXpW5UUMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/closed.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newSdsDPD1K4SMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/new.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedvWfhltiTIiMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedb9orQ8fuHlMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/job/paused.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexhgJJlwMGWlMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/plans/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingska5LQnMuLjMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/settings.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: indexTVLtO2QwbbMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/team/index.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexCucEYoo0P5Meta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/thanks/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexQ8qwcDyOrgMeta || {},
    component: () => import("/tmp/8dd1e17a081b358/pages/user/index.vue")
  }
]